<template>
  <div class="row">
    <div class="col-md-12">
      <label class="sub-title" for="baseNodeByRadius">BNs by Radius</label>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="address">Latitude</label>
        <input
          type="text"
          name="latitude"
          class="form-control"
          v-model="bn_latitude"
          :class="{
            'border-danger': !checkCoordinates(bn_latitude, 'latitude'),
          }"
          @change="findBNs"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="longitude">Longitude</label>
        <input
          type="text"
          name="location"
          class="form-control"
          v-model="bn_longitude"
          :class="{
            'border-danger': !checkCoordinates(bn_longitude, 'longitude'),
          }"
          @change="findBNs"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="height">Radius</label>
        <input
          type="text"
          name="height"
          class="form-control"
          v-model="bn_radius"
          :class="{ 'border-danger': !checkNumber(bn_radius) }"
          @change="findBNs"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    operatorKey: String,
    operator: String,
  },
  data() {
    return {
      successful: false,
      bn_latitude: "",
      bn_longitude: "",
      bn_radius: "",
      baseNodeErrorMsg: "",
      baseNodeList: {
        showBNRadius: false,
      },
    };
  },
  watch: {
    operator(newVal) {
      if (newVal) {
        this.findBNs();
      }
    },
  },
  methods: {
    checkNumber(value) {
      return this.$utils.isValidNumber(value);
    },
    checkCoordinates(value, type = "") {
      return this.$utils.isValidCoordinates(value, type);
    },
    findBNs() {
      if (
        this.bn_latitude != "" &&
        this.bn_longitude != "" &&
        this.bn_radius != "" &&
        this.operator != "" &&
        this.operatorKey != ""
      ) {
        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/find/bns/by-radius";

        let jsonObject = {};
        jsonObject.operator = this.operator;
        jsonObject.latitude =
          this.bn_latitude != "" ? parseFloat(this.bn_latitude) : "";
        jsonObject.longitude =
          this.bn_longitude != "" ? parseFloat(this.bn_longitude) : "";
        jsonObject.radius = this.bn_radius;

        // Define the request data
        const requestData = {
          post_url: postUrl,
          api_key: this.operatorKey,
          json_data: jsonObject,
        };

        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });
        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              loader.hide();
              if (response.data.detail) {
                this.baseNodeErrorMsg = response.data.detail;
              } else if (response.data.bn_list) {
                jsonObject.bn_list = response.data.bn_list;
                this.baseNodeList = jsonObject;
                this.$emit("base-node-list", this.baseNodeList);
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.$emit("get-operators");
      }
    },
  },
};
</script>
