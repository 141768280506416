<template>
  <my-header v-if="!hideHeader"></my-header>
  <router-view></router-view>
</template>

<script>
import Header from "./components/layouts/HeaderLayout.vue";

export default {
  name: "App",
  components: {
    "my-header": Header,
  },
  data() {
    return {
      hideHeader: true, // Set to true to hide header for Resources component
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "Operators") {
        this.hideHeader = true;
      } else {
        this.hideHeader = false;
      }
    },
  },
};
</script>
