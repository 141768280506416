import "@popperjs/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/style.css";
import "semantic-ui-css/semantic.min.css";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import utils from "./utils";
import axios from "./axios";

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$utils = utils;
app.use(LoadingPlugin);
app.use(router);
app.mount("#app");
