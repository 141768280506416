<template>
  <div class="map-container" ref="mapDivRef"></div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default {
  name: "GoogleMap",
  props: {
    baseNodeList: Object,
    remoteAddress: String,
    baseNode: Object,
    remoteNode: Object,
  },
  setup(props, { emit }) {
    const mapDivRef = ref(null);
    let map = null;
    let route = null;
    let baseMarker = null;
    let remoteMarker = null;
    let infoWindow = null;
    let bnEncodedSVG = null;
    let rnEncodedSVG = null;
    let baseNodeMarkers = [];
    let radiusCircle = null;
    let markerClusterer = null;

    // Define the callback function to initialize the map
    const initMap = () => {
      const options = {
        styles: [
          {
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
        ],
      };

      const mapOptions = {
        center: { lat: 33.567395, lng: -97.02491 },
        zoom: 12,
        disableDefaultUI: true,
        options,
      };

      if (mapDivRef.value) {
        mapDivRef.value.style.height = window.innerHeight - 40 + "px";
      }

      map = new google.maps.Map(mapDivRef.value, mapOptions);
      infoWindow = new google.maps.InfoWindow();

      const bnSVGIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
        <path d="M18,0 C25.732,0 32,6.641 32,14.6 C32,27.963 18,40 18,40 C18,40 4,28.064 4,14.6 C4,6.641 10.268,0 18,0 Z" id="Shape" fill="#025287" />
        <circle cx="18" cy="18" r="7" fill="#000"/>
      </svg>`;

      // Encode the SVG to a Data URL
      bnEncodedSVG =
        "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(bnSVGIcon);

      const rnSVGIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
        <path d="M18,0 C25.732,0 32,6.641 32,14.6 C32,27.963 18,40 18,40 C18,40 4,28.064 4,14.6 C4,6.641 10.268,0 18,0 Z" id="Shape" fill="#c14d23" />
        <circle cx="18" cy="18" r="7" fill="#000"/>
      </svg>`;

      // Encode the SVG to a Data URL
      rnEncodedSVG =
        "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(rnSVGIcon);

      // Initially update the map with the provided props
      updateMap(props.baseNode, props.remoteNode);
    };

    onMounted(() => {
      const key = process.env.VUE_APP_GOOGLEMAPS_KEY;

      // Set the initMap function to the window object to ensure it's accessible globally
      window.initMap = initMap;

      // Load the necessary libraries asynchronously
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Event listener for window resize
      window.addEventListener("resize", resizeMap);
    });

    // Watch for changes in props
    watch(
      [
        () => props.baseNodeList,
        () => props.remoteAddress,
        () => props.baseNode,
        () => props.remoteNode,
      ],
      ([baseNodeList, remoteAddress, baseNode, remoteNode]) => {
        if (map) {
          if (baseNodeList.bn_list) {
            updateMapWithBaseNodes(baseNodeList);
          }
          if (remoteAddress) geocodeAddress(remoteAddress);
          updateMap(baseNode, remoteNode);
        }
      },
      { deep: true }
    );

    const geocodeAddress = (address) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          const latLng = {
            lat: location.lat(),
            lng: location.lng(),
          };
          placeAndDragMarker(latLng);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    };

    const getAddressFromLatLng = (latLng, callback) => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address;
            callback(address);
          } else {
            console.log("No results found");
            callback(null);
          }
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });
    };

    const placeAndDragMarker = (location, bnExist = false) => {
      let rnMarkerIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#c14d23",
        fillOpacity: 1,
        strokeWeight: 10,
        strokeColor: "#c14d23",
        strokeOpacity: 0.4,
      };
      // Create a Data URL from your SVG with custom background and stroke colors
      if (!bnExist) {
        rnMarkerIcon = {
          url: rnEncodedSVG, // Use the encoded SVG Data URL
          scaledSize: new google.maps.Size(40, 40), // Set the size of the icon
          anchor: new google.maps.Point(20, 40),
        };
      }

      if (remoteMarker) {
        remoteMarker.setPosition(location);
      } else {
        remoteMarker = new google.maps.Marker({
          position: location,
          icon: rnMarkerIcon,
          map,
          draggable: bnExist ? false : true,
          title: "RN Marker",
        });

        remoteMarker.addListener("dragend", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          updateLocationInfo(newLatLng);
        });

        remoteMarker.addListener("click", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          showInfoWindow(newLatLng, remoteMarker);
        });
      }

      map.setCenter(location);
    };

    const updateLocationInfo = (latLng) => {
      getAddressFromLatLng(latLng, (address) => {
        emit("update-lat-lng", { coordinates: latLng, address });
      });
    };

    const showInfoWindow = (latLng, marker) => {
      getAddressFromLatLng(latLng, (address) => {
        let newLatitude = latLng.lat.toFixed(6);
        let newLongitude = latLng.lng.toFixed(6);
        const contentString = `
          <div class="info-window">
            <h3>Location Info</h3>
            <p><strong>Address:</strong> ${address || "N/A"}</p>
            <p><strong>Latitude:</strong> ${newLatitude}</p>
            <p><strong>Longitude:</strong> ${newLongitude}</p>
          </div>
        `;

        if (infoWindow) infoWindow.close();
        infoWindow.setContent(contentString);
        infoWindow.open(map, marker);
      });
    };

    const calculateCenterPoint = (nodes) => {
      const totalNodes = nodes.length;
      const sumLat = nodes.reduce((sum, node) => sum + node.latitude, 0);
      const sumLng = nodes.reduce((sum, node) => sum + node.longitude, 0);

      return {
        lat: sumLat / totalNodes,
        lng: sumLng / totalNodes,
      };
    };

    const updateMapWithBaseNodes = (baseNodeList) => {
      let bnMarkerIcon = {
        url: bnEncodedSVG,
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(20, 40),
      };

      // Define MarkerClusterer options
      const clusterOptions = {
        minimumClusterSize: 2,
        maxZoom: 15,
      };

      // Clear previous MarkerClusterer and markers
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }

      baseNodeMarkers.forEach((marker) => marker.setMap(null));
      baseNodeMarkers = [];

      if (radiusCircle) {
        radiusCircle.setMap(null);
      }

      if (!baseNodeList.showBNRadius) {
        return;
      }

      const usedCoordinates = new Set();

      if (baseNodeList.bn_list.length > 0) {
        let baseNodeRadius = parseInt(baseNodeList.radius);
        let centerPoint = calculateCenterPoint(baseNodeList.bn_list);

        let zoomLevel = 10;

        if (baseNodeRadius > 10000) {
          // Calculate the center point based on the average location of all nodes
          centerPoint = {
            lat: baseNodeList.latitude,
            lng: baseNodeList.longitude,
          };
          zoomLevel = 8;
        }

        radiusCircle = new google.maps.Circle({
          strokeColor: "#4285F4",
          strokeOpacity: 0.6,
          strokeWeight: 2,
          fillColor: "#4285F4",
          fillOpacity: 0.1,
          map,
          center: centerPoint,
          radius: baseNodeRadius,
        });

        baseNodeList.bn_list.forEach((node) => {
          let lat = node.latitude;
          let lng = node.longitude;
          let coordinateKey = `${lat.toFixed(6)},${lng.toFixed(6)}`; // Key to check uniqueness

          // Apply offset if coordinate already exists
          while (usedCoordinates.has(coordinateKey)) {
            lat += Math.random() * 0.0001 - 0.00005; // Slight offset
            lng += Math.random() * 0.0001 - 0.00005;
            coordinateKey = `${lat.toFixed(6)},${lng.toFixed(6)}`;
          }

          // Add unique coordinates to the set
          usedCoordinates.add(coordinateKey);

          const baseNodeMarker = new google.maps.Marker({
            position: { lat, lng },
            icon: bnMarkerIcon,
            title: "Base Node Marker",
          });

          baseNodeMarker.addListener("click", () => {
            const contentString = `
          <div class="info-window">
            <h3>Base Node Information</h3>
            <p>Cell: ${node.cell}</p>
            <p>Site: ${node.site}</p>
            <p>Sector: ${node.sector}</p>
            <p>Latitude: ${node.latitude}</p>
            <p>Longitude: ${node.longitude}</p>
            <p>Subscribers: ${node.subscribers}</p>
            <p>Firmware: ${node.firmware}</p>
            <p>Updated: ${new Date(node.timestamp).toLocaleString()}</p>
          </div>
        `;

            infoWindow.setContent(contentString);
            infoWindow.open(map, baseNodeMarker);
          });

          baseNodeMarkers.push(baseNodeMarker);
        });

        map.setZoom(zoomLevel);
      }

      markerClusterer = new MarkerClusterer({
        markers: baseNodeMarkers,
        map: map,
        ...clusterOptions,
      });
    };

    const updateMap = (baseNode, remoteNode) => {
      // Clear existing polyline and markers
      if (route) {
        route.setMap(null); // Remove polyline
        route = null; // Reset route variable
      }
      if (baseMarker) {
        baseMarker.setMap(null); // Remove base marker
        baseMarker = null; // Reset baseMarker variable
      }
      if (remoteMarker) {
        remoteMarker.setMap(null); // Remove remote marker
        remoteMarker = null; // Reset remoteMarker variable
      }

      // Define coordinates for two points (replace with actual coordinates)
      const baseLatLng = new google.maps.LatLng(baseNode.lat, baseNode.lng);
      const remoteLatLng = new google.maps.LatLng(
        remoteNode.lat,
        remoteNode.lng
      );

      let baseNodeExist = false;
      let remoteNodeExist = false;

      if (baseNode.lat != "" && baseNode.lng != "") {
        // Add marker for the base node
        baseMarker = new google.maps.Marker({
          position: baseLatLng,
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: "#025287",
            fillOpacity: 1,
            strokeWeight: 10,
            strokeColor: "#025287",
            strokeOpacity: 0.4,
          },
          title: "BN Marker",
        });

        baseMarker.addListener("click", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          showInfoWindow(newLatLng, baseMarker);
        });

        map.setCenter(baseNode);
        baseNodeExist = true;
      }

      if (remoteNode.lat != "" && remoteNode.lng != "") {
        placeAndDragMarker(remoteNode, baseNodeExist);
        remoteNodeExist = true;
      }

      if (baseNodeExist && remoteNodeExist) {
        // Define bounds to encompass both base and remote nodes
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(baseLatLng);
        bounds.extend(remoteLatLng);

        // Fit the map to these bounds
        map.fitBounds(bounds);

        // Calculate distance between base and remote nodes
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          baseLatLng,
          remoteLatLng
        );

        // Adjust zoom level based on distance
        let zoomLevel = 12; // Default zoom level
        if (distance < 1000) {
          zoomLevel = 14; // Adjust zoom level for closer distances
        } else if (distance > 10000) {
          zoomLevel = 12; // Adjust zoom level for closer distances
        } else if (distance > 100000) {
          zoomLevel = 10; // Adjust zoom level for closer distances
        } else if (distance > 1000000) {
          zoomLevel = 8; // Adjust zoom level for larger distances
        } else if (distance > 10000000) {
          zoomLevel = 6;
        }

        // Set the map's zoom to the calculated max zoom level if it exceeds it
        google.maps.event.addListenerOnce(map, "bounds_changed", () => {
          if (map.getZoom() > zoomLevel) {
            map.setZoom(zoomLevel);
          }
        });

        const lineSymbol = {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          scale: 0.8,
        };

        // Draw a polyline between the two points
        route = new google.maps.Polyline({
          path: [baseLatLng, remoteLatLng],
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "3px",
            },
          ],
        });
        route.setMap(map);
      }
    };

    const resizeMap = () => {
      if (mapDivRef.value) {
        mapDivRef.value.style.height = window.innerHeight - 40 + "px";
      }
    };

    return {
      mapDivRef,
    };
  },
};
</script>
