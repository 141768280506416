import { createWebHistory, createRouter } from "vue-router";
import ForbiddenPage from "./components/ForbiddenPage.vue";
import HomePage from "./components/HomePage.vue";
import OperatorsPage from "./components/OperatorsPage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorView",
    component: () => import("./components/ErrorPage.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: ForbiddenPage,
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
    meta: { hideForAuth: false },
  },
  {
    path: "/operators",
    name: "Operators",
    component: OperatorsPage,
    meta: { hideForAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  mode: "hash",
});

export default router;
