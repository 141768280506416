const utils = {
  isValidNumber(value) {
    if (value !== "") {
      // Regular expression to match numbers (positive, negative, decimals)
      const numberRegex = /^-?\d+(\.\d+)?$/;
      return numberRegex.test(value);
    }
    return true;
  },

  isValidCoordinates(value, type = "") {
    type = type.toLowerCase(); // Case-insensitive check

    // Full coordinates validation
    if (value !== "" && type === "") {
      const parts = value.split(",");
      if (parts.length !== 2) return false;

      const lat = parseFloat(parts[0]);
      const lon = parseFloat(parts[1]);

      return !isNaN(lat) && !isNaN(lon) && lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
    }

    // Latitude validation only
    if (value !== "" && type === "latitude") {
      const lat = parseFloat(value);
      return !isNaN(lat) && lat >= -90 && lat <= 90;
    }

    // Longitude validation only
    if (value !== "" && type === "longitude") {
      const lon = parseFloat(value);
      return !isNaN(lon) && lon >= -180 && lon <= 180;
    }

    return true; // If the value is empty, we consider it valid
  }
};

export default utils;
