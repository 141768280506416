<template>
  <nav class="navbar navbar-expand-lg navbar-light header-bg sticky-top">
    <div class="container-fluid">
      <router-link to="/home" class="navbar-brand me-0"
        ><img
          src="../../assets/images/logo-white.png"
          alt=""
          height="30"
          class="d-inline-block align-text-top"
      /></router-link>
    </div>
  </nav>
</template>
